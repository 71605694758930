import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { socket } from "../../helpers/socket";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";

const config = require("../../config/config.json");

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function WhatsappQRCode(props) {
  const getAuthCode = (OTP_LENGTH = 6) => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < OTP_LENGTH; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP.toString();
  };

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [authRoom, setAuthRoom] = useState(getAuthCode(6));
  const [redirectURL, setRedirectURL] = React.useState(config.websiteUrl);
  const {
    setSuccessMessage,
    setErrorMessage,
    setAccounts,
    setOTPrequestID,
    setPhoneNumber,
    setOTP
  } = props;
  const query = useQuery();

  useEffect(() => {
    if (query.get("redirect")) {
      // Set the redirect URL as a cookie (no use case for now)
      const cookies = new Cookies();
      cookies.set("redirect", query.get("redirect"), { path: "/" });
      setRedirectURL(query.get("redirect"));
    }

    function onConnect() {
      socket.emit("join", "AUTH-" + authRoom);
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onTokenEvent(token) {
      const redirectUrlToken = new URL(redirectURL);
      redirectUrlToken.searchParams.set("token", token);
      redirectUrlToken.searchParams.set("mode", "authorization");

      window.location.href = redirectUrlToken;
    }

    function onMessageEvent(value) {
      setSuccessMessage(value);
    }

    function onErrorEvent(value) {
      setErrorMessage(value);
    }

    function onPhoneEvent(value) {
      setPhoneNumber(value);
    }

    function onOTPrequestEvent(value) {
      setOTPrequestID(value);
      setOTP(authRoom);
    }

    function onAccountsEvent(value) {
      if (value) {
        const accounts = JSON.parse(value);
        setAccounts(accounts);
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('token', onTokenEvent);
    socket.on('message', onMessageEvent);
    socket.on('error', onErrorEvent);
    socket.on('accounts', onAccountsEvent);
    socket.on('phone', onPhoneEvent);
    socket.on('OTPrequest', onOTPrequestEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('token', onTokenEvent);
      socket.off('message', onMessageEvent);
      socket.off('error', onErrorEvent);
      socket.off('accounts', onOTPrequestEvent);
      socket.off('phone', onPhoneEvent);
      socket.off('OTPrequest', onOTPrequestEvent);
    };
  }, []);

  setTimeout(async () => {
    socket.disconnect();
  }, 60000 * 30);

  return (
    isConnected && <div className="text-center mt-4">
      <h3
        className={
          "font-semibold text-md text-blueGray-700"
        }
      >Scan QR to login with Whatsapp
      </h3>
      <div className={"QRCode"} style={{
        height: "auto",
        maxWidth: "55%",
        width: "100%",
        margin: "0 auto"
      }}>
        <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={"https://wa.me/" + config.whatsappPhone + "?text=AUTH-" + authRoom + "%20-%20Sign" +
            "%20into%20my%20SuperProcure%20account%20now."}
          viewBox={`0 0 256 256`}
          bgColor={"#e2e8f0"}
        />
        or <a href={"https://wa.me/" + config.whatsappPhone + "?text=AUTH-" + authRoom + " - Sign" +
        " into my SuperProcure account now."} rel={"noreferrer"} target={"_blank"}
              className={"text-blueGray-500 font-bold underline"}>click here</a>
      </div>
    </div>
  );
}
