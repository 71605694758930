import React from "react";
import Cookies from "universal-cookie";

import { PublicClientApplication } from "@azure/msal-browser";
import OTPInput, { ResendOTP } from "otp-input-react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import SP_API from "../../components/API";
import config from "../../config/config.json";
import LoginButton from "./LoginButton";
import LoginEmail from "./LoginEmail";
import LoginPhone from "./LoginPhone";
import WhatsappQRCode from "./WhatsappQRCode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginSSO from "./LoginSSO";
import { MsalProvider } from "@azure/msal-react";

const cookies = new Cookies();

// MSAL configuration
const pca = new PublicClientApplication({
    auth: {
        clientId: config.MS_OAUTH_CLIENT_ID
    }
});

export default function LoginTabs(props) {
    const OTPlength = 6;
    const OTPwaitingTime = 60;

    const {
        phoneNumber,
        email,
        setOTP,
        setPhoneNumber,
        setEmail,
        setErrorMessage,
        setSuccessMessage,
        setSendingOTP,
        setOTPrequestID,
        validateOTP,
        validatingOTP,
        sendingOTP,
        OTP,
        setAccounts,
        verifySsoToken,
    } = props;

    const [sentOTP, setSentOTP] = React.useState(false);
    const [openTab, setOpenTab] = React.useState(
        cookies.get("login_type") ? parseInt(cookies.get("login_type")) : 1
    );
    const [loginButton, setLoginButton] = React.useState(null);

    const changeOpenTab = (tab) => {
        setOpenTab(tab);
        setErrorMessage(null);
        setSentOTP(false);
        setOTP(false);
    };

    const OTPchanged = (e) => {
        setOTP(e);
        if (e.length === 6) {
            setTimeout(() => {
                loginButton.focus();
            }, 100);
        }
    };

    const sendOTP = () => {
        // Request for OTP

        let options;

        if (openTab === 1 && phoneNumber) {
            options = { url: "auth/phone", data: { phone: phoneNumber } };
        } else if (openTab === 2 && email) {
            options = { url: "auth/email", data: { email: email } };
        }

        if (options) {
            setOTP("");
            setSuccessMessage(null);
            setSendingOTP(true);
            SP_API(options)
                .then((response) => {
                    if (response.data && response.data.id) {
                        setOTPrequestID(response.data.id);
                        setSendingOTP(false);
                        setSentOTP(true);
                        setErrorMessage(null);
                        setSuccessMessage(response.message);
                    } else {
                        setErrorMessage(
                            "An unknown error occurred. Please try again."
                        );
                        setSendingOTP(false);
                    }
                })
                .catch((err) => {
                    if (err && err.status && err.data && err.data.message) {
                        setErrorMessage(err.data.message);
                    } else {
                        setErrorMessage(
                            "An unknown error occurred. Please try again."
                        );
                    }
                    setSendingOTP(false);
                });
        }
    };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                        role="tablist"
                    >
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a
                                className={
                                    "text-xs uppercase py-3 font-bold block " +
                                    (openTab === 1
                                        ? "text-sp-900 border-b-2 border-sp-900 hover:text-sp-800"
                                        : "text-blueGray-700 hover:text-blueGray-500")
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    cookies.set("login_type", 1, { path: "/" });
                                    changeOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#phone"
                                role="tablist"
                            >
                                <FormattedMessage
                                    id="auth.mobile_number"
                                    defaultMessage="Mobile number"
                                    description="Mobile number label"
                                />
                            </a>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a
                                className={
                                    "text-xs uppercase py-3 font-bold block " +
                                    (openTab === 2
                                        ? "text-sp-900 border-b-2 border-sp-900 hover:text-sp-800"
                                        : "text-blueGray-700 hover:text-blueGray-500")
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    cookies.set("login_type", 2, { path: "/" });
                                    changeOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#email"
                                role="tablist"
                            >
                                <FormattedMessage
                                    id="auth.email_address"
                                    defaultMessage="E-mail address"
                                    description="E-mail address label"
                                />
                            </a>
                        </li>
                    </ul>
                    <div className="flex-auto">
                        <div className="tab-content tab-space">
                            <div
                                className={openTab === 1 ? "block" : "hidden"}
                                id="phone"
                            >
                                <LoginPhone
                                  setPhoneNumber={setPhoneNumber}
                                  OTP={OTP}
                                  setOTP={setOTP}
                                  sendOTP={sendOTP}
                                  sendingOTP={sendingOTP}
                                  sentOTP={sentOTP}
                                  validateOTP={validateOTP}
                                  validatingOTP={validatingOTP}
                                  setErrorMessage={setErrorMessage}
                                ></LoginPhone>
                            </div>
                            <div
                              className={openTab === 2 ? "block" : "hidden"}
                              id="email"
                            >

                                <LoginEmail
                                  sendOTP={sendOTP}
                                  setEmail={setEmail}
                                  sentOTP={sentOTP}
                                  sendingOTP={sendingOTP}
                                ></LoginEmail>
                            </div>
                        </div>
                    </div>
                    {sentOTP ? (
                        <div className="transition-all ease-linear duration-150">
                            <div className="relative w-full py-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-otp"
                                >
                                    <FormattedMessage
                                        id="auth.enter_otp"
                                        defaultMessage="Enter SMS code"
                                        description="Enter code label"
                                    />
                                </label>
                                <OTPInput
                                    value={OTP}
                                    onChange={OTPchanged}
                                    autoFocus={true}
                                    OTPLength={OTPlength}
                                    otpType="number"
                                    inputClassName="otp_input flex-1 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                    disabled={validatingOTP}
                                />

                                <ResendOTP
                                    maxTime={OTPwaitingTime}
                                    renderTime={() => React.Fragment}
                                    onResendClick={() =>
                                        console.log("Resend clicked")
                                    }
                                    renderButton={(buttonProps) => {
                                        return (
                                            <button
                                                onClick={() => sendOTP()}
                                                className="text-blueGray-700 hover:text-blueGray-500 text-xs py-3"
                                                disabled={
                                                    buttonProps.remainingTime !==
                                                    0
                                                }
                                            >
                                                {buttonProps.remainingTime !==
                                                0 ? (
                                                    <span>
                                                        {
                                                            buttonProps.remainingTime
                                                        }{" "}
                                                        <FormattedPlural
                                                            value={
                                                                buttonProps.remainingTime
                                                            }
                                                            one="second "
                                                            other="seconds "
                                                        />
                                                        <FormattedMessage
                                                            id="auth.otp_seconds_left"
                                                            defaultMessage="to request a new code"
                                                        />
                                                    </span>
                                                ) : (
                                                    <FormattedMessage
                                                        id="auth.request_new_otp"
                                                        defaultMessage="Request a new code"
                                                        description="Request a new code"
                                                    />
                                                )}
                                            </button>
                                        );
                                    }}
                                />
                            </div>
                            <div>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        id="rememberme"
                                        type="checkbox"
                                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                    />
                                    <span className="ml-2 text-sm font-semibold text-blueGray-600">
                                        <FormattedMessage
                                          id="auth.remember_me"
                                          defaultMessage="Remember me"
                                          description="Remember me checkbox"
                                        />
                                    </span>
                                </label>
                            </div>
                        </div>
                    ) : null}

                    {openTab !== 3 ? (
                      <LoginButton
                        validateOTP={validateOTP}
                        openTab={openTab}
                        phoneNumber={phoneNumber}
                        OTP={OTP}
                        email={email}
                        sendOTP={sendOTP}
                        setLoginButton={setLoginButton}
                        sentOTP={sentOTP}
                        validatingOTP={validatingOTP}
                        sendingOTP={sendingOTP}
                      ></LoginButton>
                    ) : null}
                    {!config.blockSSO ?
                      <MsalProvider instance={pca}>
                          <GoogleOAuthProvider
                            clientId={config.GOOGLE_OAUTH_CLIENT_ID}
                          >
                              <LoginSSO
                                verifySsoToken={verifySsoToken}
                                validatingOTP={validatingOTP}
                              />
                          </GoogleOAuthProvider>
                      </MsalProvider> : ""}
                    {openTab === 1 && config.whatsappPhone && (
                      <WhatsappQRCode
                        setErrorMessage={setErrorMessage}
                        setSuccessMessage={setSuccessMessage}
                        setAccounts={setAccounts}
                        setPhoneNumber={setPhoneNumber}
                        setOTP={setOTP}
                        setOTPrequestID={setOTPrequestID}
                      ></WhatsappQRCode>
                    )}
                </div>
            </div>
        </>
    );
}
