import React from "react";
import { nanoid } from "nanoid";
import { FormattedMessage } from "react-intl";

// For multi lingual conversion

export default function LoginAccounts(props) {
  // Props to inherit from the parent (Login.js)
  const { accounts, setSuccessMessage, validateOTP } = props;
  const key = nanoid();
  // Login to selected account
  const loginAccount = (selected_account) => {
    // Clear success message
    setSuccessMessage("Logging you in...");
    validateOTP(selected_account);
  };

  const accountsMap = new Map();
  
  accounts.forEach((account) => {
    let description = account.description
    if(accountsMap.has(description)){
      const ct = accountsMap.get(description);
      accountsMap.set(description, ct+1);
    }
    else{
      accountsMap.set(description, 1)
    }
  });
  accounts.forEach((obj) => {
    if(accountsMap.get(obj.description)>1){
      obj.showMobile = true;
    }
  })
 
  return (
    <>
      <div className="text-center mt-3 mb-3">

        <h6 className="text-blueGray-500 text-sm font-bold">
          <FormattedMessage
            id="auth.multiple_accounts_found"
            defaultMessage="We found multiple accounts."
            description="Multiple accounts title"
          /><br />
          <FormattedMessage
            id="auth.choose_account_sign_in"
            defaultMessage="Please choose the account to sign-in to."
            description="Multiple accounts title"
          />
        </h6>
      </div>
      { // Make it a two column layout if there are more than 4 accounts
      }
      <div className={accounts.length > 4 ? "grid md:grid-cols-2 gap-3" : ""}>
        { // For each account in array
          accounts.map((account, i) => {
            return (
              <div className="max-w-sm w-full lg:max-w-full" key={"account_" + i}
                   onClick={() => loginAccount(account.selection)}>
                <div
                  className="max-w-sm rounded overflow-hidden shadow-lg bg-white cursor-pointer mb-2 p-3">
                  <div>
                    <p className="text-xs text-gray-600 flex items-center uppercase">
                      {account.type}
                    </p>
                    <div
                      className="text-gray-900 text-sm font-bold md mt-1 hover:text-sp-800">{account.description}
                    </div>
                    {account.showMobile===true && <div>
                      <small>{account.username}</small><br></br>
                      <small><small>Phone no. ending with</small></small>
                      <small className="text-xs text-gray-600 flex items-center">
                      {account.mobile}
                      </small>
                    </div>}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </>
  );
}
