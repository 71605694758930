
const axios = require("axios");
const config = require("../config/config.json");

// TO DO - Add Redis

const api = axios.create();

const SP_API = function (options, api_endpoint="auth", cached=false) {
  // Compose the URL
    options.url = [config.api[api_endpoint], options.url].join("/");

    // Change the request method to POST if there is a payload.
    if (options.data) {
      options.method = 'post';
    }

  // Set timeout to 30s unless specified otherwise.
  if (!options.timeout) {
      options.timeout = 30000;
    }

// On Success, return the response payload.
    const onSuccess = function (response) {
    console.debug("Request Successful!", response);
    return response.data;
    };
    // On Error, console log. 
    const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }
    // Reject the promise
    return Promise.reject(error.response || error.message);
    };
    
  if (cached) {
    console.log("Cached");
      return api(options).then(onSuccess).catch(onError);
  } else {
    return api(options).then(onSuccess).catch(onError);
  }
};

export default SP_API;
