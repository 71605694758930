import React from "react";
import { IntlContext } from "../components/Wrapper.js";

const languages = [
  { name: "English", code: "en" },
  { name: "हिंदी", code: "hi" },
  { name: "বাংলা", code: "bn" },
  { name: "عربي", code: "ar" },
  { name: "தமிழ்", code: "ta" },
  { name: "Español", code: "es" },
  { name: "Français", code: "fr" },
  { name: "Malay", code: "ms" },
  { name: "汉语/漢語", code: "zh" },
  { name: "日本語", code: "ja" }
];

const LanguagePicker = () => (
  <IntlContext.Consumer>
    {({
        switchToEnglish,
        switchToHindi,
        switchToBengali,
        switchToArabic,
        switchToTamil,
        switchToSpanish,
        switchToChinese,
        switchToJapanese,
        switchToMalay,
        switchToFrench,
        locale
      }) => (
      <select
        value={locale}
        className="text-xs border-0 shadow rounded bg-white text-blueGray-600"
        onChange={(e) => {
          switch (e.target.value) {
            case "ar":
              switchToArabic();
              break;
            case "bn":
              switchToBengali();
              break;
            case "es":
              switchToSpanish();
              break;
            case "fr":
              switchToFrench();
              break;
            case "hi":
              switchToHindi();
              break;
            case "ja":
              switchToJapanese();
              break;
            case "ms":
              switchToMalay();
              break;
            case "ta":
              switchToTamil();
              break;
            case "zh":
              switchToChinese();
              break;
            default:
              switchToEnglish();
          }
        }}
      >
        {languages.map(({ name, code }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
    )}
  </IntlContext.Consumer>
);
export default LanguagePicker;
