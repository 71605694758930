import React from "react";
import Cookies from "universal-cookie";
import { IntlProvider } from "react-intl";

import arTranslation from "../translations/ar.json";
import bnTranslation from "../translations/bn.json";
import enTranslation from "../translations/en.json";
import esTranslation from "../translations/es.json";
import frTranslation from "../translations/fr.json";
import hiTranslation from "../translations/hi.json";
import jaTranslation from "../translations/ja.json";
import msTranslation from "../translations/ms.json";
import taTranslation from "../translations/ta.json";
import zhTranslation from "../translations/zh.json";

const Context = React.createContext();
const cookies = new Cookies();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    this.switchToEnglish = () => {
      this.setState({ locale: "en", messages: enTranslation });
      cookies.set("lang", "en", { path: "/" });
    };
    this.switchToHindi = () => {
      this.setState({ locale: "hi", messages: hiTranslation });
      cookies.set("lang", "hi", { path: "/" });
    };
    this.switchToArabic = () => {
      this.setState({ locale: "ar", messages: arTranslation });
      cookies.set("lang", "ar", { path: "/" });
    };
    this.switchToTamil = () => {
      this.setState({ locale: "ta", messages: taTranslation });
      cookies.set("lang", "ta", { path: "/" });
    };
    this.switchToBengali = () => {
      this.setState({ locale: "bn", messages: bnTranslation });
      cookies.set("lang", "bn", { path: "/" });
    };
    this.switchToChinese = () => {
      this.setState({ locale: "zh", messages: zhTranslation });
      cookies.set("lang", "zh", { path: "/" });
    };
    this.switchToJapanese = () => {
      this.setState({ locale: "ja", messages: jaTranslation });
      cookies.set("lang", "ja", { path: "/" });
    };
    this.switchToMalay = () => {
      this.setState({ locale: "ms", messages: msTranslation });
      cookies.set("lang", "ms", { path: "/" });
    };
    this.switchToFrench = () => {
      this.setState({ locale: "fr", messages: frTranslation });
      cookies.set("lang", "fr", { path: "/" });
    };
    this.switchToSpanish = () => {
      this.setState({ locale: "es", messages: esTranslation });
      cookies.set("lang", "es", { path: "/" });
    };
    // pass everything in state to avoid creating object inside render method (like explained in
    // the documentation)
    this.state = {
      locale: navigator.language ? navigator.language.split(/[-_]/)[0] : "en",
      messages: enTranslation,
      switchToEnglish: this.switchToEnglish,
      switchToHindi: this.switchToHindi,
      switchToBengali: this.switchToBengali,
      switchToArabic: this.switchToArabic,
      switchToTamil: this.switchToTamil,
      switchToSpanish: this.switchToSpanish,
      switchToChinese: this.switchToChinese,
      switchToJapanese: this.switchToJapanese,
      switchToMalay: this.switchToMalay,
      switchToFrench: this.switchToFrench
    };
    if (cookies.get('lang')) {
      this.state.locale = cookies.get("lang");
      switch (cookies.get("lang")) {
        case "ar":
          this.state.messages = arTranslation;
          break;
        case "bn":
          this.state.messages = bnTranslation;
          break;
        case "es":
          this.state.messages = esTranslation;
          break;
        case "fr":
          this.state.messages = frTranslation;
          break;
        case "hi":
          this.state.messages = hiTranslation;
          break;
        case "ja":
          this.state.messages = jaTranslation;
          break;
        case "ms":
          this.state.messages = msTranslation;
          break;
        case "ta":
          this.state.messages = taTranslation;
          break;
        case "zh":
          this.state.messages = zhTranslation;
          break;
        default:
          this.state.messages = enTranslation;
      }
    }
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
