import React from "react";
import { FormattedMessage } from "react-intl";

export default function LoginEmail(props) {

  const { setEmail, sendOTP, sentOTP, sendingOTP } = props;
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validateEmail = (e) => {
    if (e.target.value && e.target.value.match(emailRegex)) {
      setEmail(e.target.value);
    } else {
      setEmail(null);
    }
  };

  return (
    <div className="relative w-full py-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
      >
        <FormattedMessage
          id="auth.enter_email"
          defaultMessage="Enter e-mail address"
          description="Enter e-mail address label"
        />
      </label>
      <input
        type="email"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        onChange={validateEmail}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sendOTP();
          }
        }}
        placeholder="E-mail address"
      />

      {sendingOTP || sentOTP ? (
        <span className="absolute right-0 text-lg mr-3 mt-2">
                {sendingOTP ? (
                  <i className="fa-solid fa-circle-notch fa-spin text-sp-800"></i>
                ) : (
                  <i className="fa-solid fa-check text-green-500"></i>
                )}
              </span>
      ) : null}
    </div>
  );
}
