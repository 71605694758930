import React from "react";
import { FormattedMessage } from "react-intl";

export default function LoginButton(props) {
  const {
    validateOTP,
    openTab,
    phoneNumber,
    OTP,
    email,
    sendOTP,
    sentOTP,
    sendingOTP,
    validatingOTP,
    setLoginButton
  } = props;
  // Setting this as variable to enable the button after OTP is typed in.
  return (
    <div className="text-center mt-4">
      {sentOTP ? (
        <button
          className={"text-white active:bg-sp-800 text-sm font-bold uppercase px-6 py-3 rounded" +
            " shadow hover:shadow-lg outline-none focus:outline-none focus:shadow-lg mr-1 mb-1" +
            " w-full" +
            " ease-linear transition-all duration-150 " +
            (validatingOTP || !OTP || OTP.length !== 6
              ? "bg-sp-200 cursor-not-allowed"
              : "bg-sp-900")
          }
          ref={(button) => {
            setLoginButton(button);
          }}
          disabled={!OTP || OTP.length !== 6}
          onClick={() => validateOTP()}
          type="button"
        >
          {validatingOTP ? (
            <FormattedMessage
              id={openTab === 1 ? "auth.validating_otp" : "auth.verifying_email"}
              defaultMessage={openTab === 1 ? "Verifying SMS code..." : "Verifying e-mail" +
                " code..."}
              description="Code validating button"
            />
          ) : (
            <FormattedMessage
              id={openTab === 1 ? "auth.validate_sms" : "auth.validate_email"}
              defaultMessage={openTab === 1 ? "Validate SMS code" : "Validate e-mail code"}
              description="Validate code button"
            />
          )}
        </button>
      ) : (
        <button
          className={
            "text-white active:bg-sp-800 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 " +
            ((openTab === 2 && !email) || (openTab === 1 && !phoneNumber) || sendingOTP
              ? "bg-sp-200 cursor-not-allowed"
              : "bg-sp-900")
          }
          type="button"
          disabled={((openTab === 2 && !email) || (openTab === 1 && !phoneNumber) || sendingOTP)}
          onClick={() => sendOTP()}
        >
          {sendingOTP ? (
            <FormattedMessage
              id={openTab === 1 ? "auth.sending_sms_otp" : "auth.sending_email_otp"}
              defaultMessage={openTab === 1 ? "Sending SMS code..." : "Sending e-mail code..."}
              description="SMS sending button"
            />
          ) : (
            <FormattedMessage
              id={openTab === 1 ? "auth.request_sms" : "auth.request_email"}
              defaultMessage={openTab === 1 ? "Request SMS code" : "Request E-mail code"}
              description="Request code button"
            />
          )
          }
        </button>
      )}
    </div>
  );
}
