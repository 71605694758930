import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import SP_API from "../../components/API";

// components

import ReactTable from "../../components/Cards/ReactTable.js";

export default function ConnectValidations() {
    const [data, setData] = useState([]);
    let { id } = useParams();

    useEffect(() => {
      async function fetch_validation() {
        let validation_info = await SP_API({
          url: "superadmin/connect/validations/" + id
        }, false, "connect");
        let rows = [];
        console.log(validation_info);

        setData(rows);
      }
      return fetch_validation();
    });


const renderCellURL = function(props) {
  if (props.cell && props.cell.value) {
    return (
    <a className="bg-gray-100 text-gray-800 text-sm font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300" href={"./validations/" + props.row.original._id}>{props.cell.value}</a>
    )
    }
  }

      const tableHeads = [{
              Header: 'ID',
              accessor: 'id',
              sortType: 'basic',
              Cell: (props) => {return renderCellURL(props)}
            },
          {
              Header: 'Definitions',
            accessor: 'definitions',
            sortType: 'basic'
          },
      ];
      
  return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ReactTable title="Validations / schemas defined" columns={tableHeads} data={data}  />
        </div>
      </div>
  );
}
