import React from "react";
import { Route, Switch as Routes } from "react-router-dom";
import { sample } from "lodash";

// components
import Login from "../views/auth/Login.js";
import Register from "../views/auth/LoginAccounts.js";

const BACKGROUND_IMAGES = [require("../assets/images/backgrounds/final-images-1.jpg"), require("../assets/images/backgrounds/final-images-2.jpg"), require("../assets/images/backgrounds/final-images-3.jpg")];
export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-20 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-sp-900 bg-no-repeat bg-cover bg-center"
            style={{
              backgroundImage:
                "url(" + sample(BACKGROUND_IMAGES) + ")"
            }}
          ></div>
          <Routes>
            <Route path="/" exact component={Login} />
            <Route path="/email" exact component={Register} />
          </Routes>
        </section>
      </main>
    </>
  );
}
