import React from "react";
import ReactDOM from "react-dom";
import { IntlProviderWrapper } from "./components/Wrapper";

import { BrowserRouter, Route, Switch as Routes, Redirect as Navigate } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/styles/tailwind.css";

import Admin from "./layouts/Admin.js";
import Auth from "./layouts/Auth.js";

import Profile from "./views/Profile.js";

import "./assets/styles/ssobtn.css";

ReactDOM.render(
  <IntlProviderWrapper>
    <BrowserRouter>
      <Routes>
        {/* add routes with layouts */}
        <Route path="/superadmin" component={Admin} />
        <Route path="/auth" component={Auth} />
        {/* add routes without layouts */}
        <Route path="/profile" exact cponent={Profile} />
        <Route path="/" exact component={Auth} />
        {/* add redirect for first page */}
        <Navigate from="*" to="/" />
      </Routes>
    </BrowserRouter>{" "}
  </IntlProviderWrapper>,
  document.getElementById("root")
);