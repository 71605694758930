import React, { useState, useEffect } from "react";
import SP_API from "../../components/API";

// components

import ReactTable from "../../components/Cards/ReactTable.js";

export default function ConnectEndpoints() {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetch_endpoints() {
    let endpoints_list = await SP_API({
        url: "superadmin/connect/endpoints"
    }, false, "connect");
    let rows = [];
    if (endpoints_list.routes) {
        endpoints_list.routes.forEach(route => {
            rows.push(route);
        });
    }
    setData(rows);
    }
    return fetch_endpoints();
}, []);

const renderValidationURLs = function(props) {
  let response = [];
if (props.cell && props.cell.value) {
  props.cell.value.forEach(p => {
    response.push(<div className="mb-2"><span className="bg-gray-100 text-gray-800 text-sm font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{p}</span></div>);
  })
}
return response;
}

      const tableHeads = [{
              Header: 'Method',
              accessor: 'method',
              sortType: 'basic'
          },
          {
              Header: 'Endpoint URL',
              accessor: 'route',
              sortType: 'basic'
          },
          {
            Header: 'Validation schemas',
            accessor: 'validations',
            Cell: (props) => {return renderValidationURLs(props)}
          },
          {
            Header: 'Transformations',
            accessor: 'transformations',
            sortType: 'basic'
          },
          {
            Header: 'Workflows',
            accessor: 'workflows',
            sortType: 'basic'
          },
          {
            Header: 'Logs',
            accessor: 'logs',
            sortType: 'basic'
          },
      ];
      
  return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ReactTable title="Endpoints" columns={tableHeads} data={data}  />
        </div>
      </div>
  );
}
