import React from "react";
import { Switch as Routes, Route, Redirect as Navigate } from "react-router-dom";

// components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

// views
import Dashboard from "../views/admin/Dashboard.js";
import Maps from "../views/admin/Maps.js";
import Users from "../views/admin/Users.js";
import Settings from "../views/admin/Settings.js";
import Tables from "../views/admin/Tables.js";

import ConnectEndpoints from "../views/connect/Endpoints.js";
import ConnectValidations from "../views/connect/Validations.js";
import ConnectValidationDetails from "../views/connect/ValidationDetails.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Routes>
            <Route path="/superadmin/dashboard" exact component={Dashboard} />
            <Route path="/superadmin/users" exact component={Users} />
            <Route path="/superadmin/maps" exact component={Maps} />
            <Route path="/superadmin/settings" exact component={Settings} />
            <Route path="/superadmin/tables" exact component={Tables} />
            <Route path="/superadmin/connect/endpoints" exact component={ConnectEndpoints} />
            <Route path="/superadmin/connect/validations" exact component={ConnectValidations} />
            <Route path="/superadmin/connect/validations/:id" exact component={ConnectValidationDetails} />
            <Navigate from="/superadmin" to="/superadmin/dashboard" />
          </Routes>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
