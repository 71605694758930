import { io } from "socket.io-client";

const config = require("../config/config.json");

export const socket = io(config.api.ws, {
  reconnectionDelay: 3000,
  reconnectionDelayMax: 5000,
  withCredentials: true,
  forceNew: true,
  transports: ["websocket"],
  "sync disconnect on unload": true
});