import React from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy } from 'react-table'

// components

export default function ReactTable({ color, title, columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    }, useSortBy);

    const renderCell = (cell) => {
        let classNames = '';

        if (cell.column && cell.column.id === "method") {
            switch (cell.value) {
                case "GET":
                    classNames = "bg-blue-100 text-blue-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800";
                    break;
                case "POST":
                    classNames = "bg-yellow-100 text-yellow-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900";
                    break;
                case "PUT":
                    classNames = "bg-pink-100 text-pink-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900";
                    break;
                case "DELETE":
                    classNames = "bg-red-100 text-red-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900";
                    break;
                default:
                    classNames = ""
            }
        } 
        return <span className = {classNames}>{cell.render('Cell')}</span>;
    }
    
  return (
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                {title}
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse hover:table-fixed" {...getTableProps()}>
          <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th
               className={
                 "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                 (color === "light"
                   ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                   : "bg-sky-800 text-sky-300 border-sky-700")
               } {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render('Header')}
                 <span>
                {column.isSorted ? (column.isSortedDesc ? <i className="fas fa-caret-down ml-2"></i> : <i className="fas fa-caret-up ml-2"></i>) : ''}
               </span>
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()} className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
               {row.cells.map(cell => {
                 return (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" {...cell.getCellProps()}>
                     {renderCell(cell)}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
          </table>
        </div>
      </div>
  );
}

ReactTable.defaultProps = {
  color: "light",
};

ReactTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
