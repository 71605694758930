import React, { useState, useEffect } from "react";
import SP_API from "../../components/API";

// components

import ReactTable from "../../components/Cards/ReactTable.js";

export default function ConnectValidations() {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetch_validations() {
    let validations_list = await SP_API({
        url: "superadmin/connect/validations"
    }, false, "connect");
    let rows = [];
    if (validations_list.routes) {
        validations_list.routes.forEach(route => {
            rows.push(route);
        });
    }
    setData(rows);
    }
    return fetch_validations();
}, []);


const renderCellURL = function(props) {
  if (props.cell && props.cell.value) {
    return (
    <a className="bg-gray-100 text-gray-800 text-sm font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300" href={"./validations/" + props.row.original._id}>{props.cell.value}</a>
    )
    }
  }

      const tableHeads = [{
              Header: 'ID',
              accessor: 'id',
              sortType: 'basic',
              Cell: (props) => {return renderCellURL(props)}
            },
          {
              Header: 'Definitions',
            accessor: 'definitions',
            sortType: 'basic'
          },
      ];
      
  return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ReactTable title="Validations / schemas defined" columns={tableHeads} data={data}  />
        </div>
      </div>
  );
}
